<template>
  <b-row>
    <b-col cols="10">
      <label>Exceção</label>
      <div class="item-search">
        <Autocomplete
          v-model="selected"
          :options="options"
          debounce="1000"
          class="w-100"
          @input="setExpense"
          @typing="getImportedExpense"
          :placeholder="
            type === 'simpro'
              ? 'Buscar código Simpro, código TUSS ou descrição'
              : 'Buscar código TUSS ou descrição'
          "
        />
      </div>
    </b-col>
    <b-col class="pt-4 mt-2">
      <b-button
        variant="primary"
        @click="addException(selected)"
        lg
      >
        Adicionar
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'tableFilters',
  components: {
    Autocomplete: () => import('@/components/Autocomplete'),
  },
  props: {
    type: {
      type: String,
      default: 'simpro',
    },
    tissMatMedConfigId: {
      type: String,
      required: true,
    },
    clinicHealthPlanId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expense: null,
      selected: null,
      options: [],
    }
  },
  methods: {
    setExpense(value) {
      this.expense = value
    },
    addException() {
      if (!this.expense) return

      this.$emit('add-exception', this.expense)
      this.selected = null
      this.expense = null
    },
    async getImportedExpense(query) {
      try {
        if (!query?.length) return
        const filters = {
          tiss_mat_med_config_id: this.tissMatMedConfigId,
        }
        const { data } = await this.api.getTissImportedExpenses(query, null, this.clinicHealthPlanId, null, filters) 

        this.options = data
          .filter(expense => !!expense?.mat_med?.status)
          .map(expense => ({
            label: `${expense.code} - ${expense.name}`,
            value: expense,
          }))

      } catch (error) {
        this.$toast.error('Houve um erro ao buscar as despesas, contate o suporte se o erro persistir.')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
label {
  color: var(--dark-blue, #0C1D59);
  font-size: 16px;
  font-family: Nunito Sans;
  font-weight: 600;
  line-height: 150%;
}

.item-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  .search {
    z-index: 3;
    margin: 0 0 2px 10px;
    width: 24px;
    height: 24px;
    position: absolute;
    stroke: var(--neutral-500, #A4B1DF);
  }

  input {
    position: relative;
    padding-left: 40px;
  }
}
</style>